import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <h1>Hi from the second page, just edited from github :)</h1>
    <p>Welcome to page 2</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
    <Link to="/">Go back to the homepage</Link>
    <Link to="https://faphouse.com/videos/pW2oy9">OK, you got me, I have some naughty videos I want to share with you.</Link>
    </p>
  </Layout>
)

export default SecondPage
